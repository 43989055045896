<template>
  <div class="white--text grey lighten-3 pa-0" fluid>
    <simple-header
      hasLeftChild
      :routes="[
        { title: 'خانه', url: '/' },
        { title: 'دسته بندی', url: '/categories' },
        { title: 'مقالات' },
      ]"
      title="مقالات"
    >
      <v-img
        :src="
          require('../../assets/icons/chat-comment-oval-speech-bubble-with-text-lines.png')
        "
        class="sp-img white-icon mr-md-auto ml-md-0 mx-auto"
      />
    </simple-header>
    <v-container>
      <v-row v-if="loading">
        <v-col sm="6" md="4" lg="3" cols="12" v-for="i in 8" :key="i">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          sm="6"
          md="4"
          lg="3"
          cols="12"
          v-for="(item, i) in items"
          :key="i"
        >
          <article-item
            :id="item.id"
            :slug="item.slug"
            :img="item.media ? item.media.url : require('../../assets/img/thumb.jpg')"
            :title="item.title"
            :date="item.created_at"
          />
        </v-col>
      </v-row>
      <my-paginate v-model="page" :length="last_page" @onChange="GetList" />
    </v-container>
  </div>
</template>

<script>
import MyPaginate from "../../components/MyPaginate.vue";
import ArticleItem from "../../components/Pages/ArticleItem.vue";
import SimpleHeader from "../../components/SimpleHeader.vue";
import MyAxios from '../../constants/MyAxios';
export default {
  components: { SimpleHeader, ArticleItem, MyPaginate },
  data() {
    return {
      items: [],
      loading: true,
      last_page: 1,
      page: 1,
    };
  },
  created() {
    this.GetList();
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/posts/index", {
        params: {
          with: ["media"],
          page: this.page,
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.last_page = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.sp-img {
  /* width: 20em;
    height: 20em; */
  max-width: 100px;
  max-height: 100px;
}
</style>
